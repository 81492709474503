<template>
  <div class="container text-center mx-auto max-w-xl p-8 pt-0 -mt-16 sm:mt-0">
    <h1 class="text-7xl sm:text-9xl pb-5 text-white font-family-oswald">
      daze<span class="text-pink">mx</span>
    </h1>
    <h2 class="text-2xl sm:text-3xl text-white">
      I make websites, occasionally*
    </h2>
    <h3 class="py-5 text-lg text-gray-400">
      &nbsp;"I've been making websites since I could type."
    </h3>
    <p class="text-gray-500 font-thin sm:text-lg">
      I'm now {{ $moment().diff(birth, 'years') }} years old &
      have over {{ $moment().diff(firstJob, 'years') }} years of professional
      experience designing, developing & maintaining websites.
      {{ $moment().diff(firstJob, 'days') | formatNumber }} days of programming, to be exact...
    </p>
    <t-button
      class="mt-6 sm:mt-8 pink"
      @click="modal=true"
    >
      👋 Say hello
    </t-button>

    <vodal
      :show="modal"
      :close-on-esc="true"
      animation="flip"
      @hide="modal=false"
    >
      <contact-form
        @close="modal=false"
      />
    </vodal>

    <footer class="absolute bottom-0 left-0 right-0 pb-6 text-xs font-light">
      <ul class="sm:flex justify-center items-center text-gray-500">
        <li class="py-1 sm:py-0 px-3">
          *basically all the time
        </li>
        <li class="py-1 sm:py-0px-3">
          &copy; {{ $moment().year() }} {{ $siteName }}
        </li>
        <li class="py-1 sm:py-0">
          <a
            href="https://github.com/dazecoop"
            target="_blank"
            class="py-2 px-3 hover:text-white transition-colors duration-300"
          >
            <font-awesome-icon
              :icon="['fab', 'github']"
              size="lg"
            />
          </a>
        </li>
        <li class="pt-1 sm:py-0">
          <a
            href="https://twitter.com/dazecoop"
            target="_blank"
            class="py-2 px-3 hover:text-white transition-colors duration-300"
          >
            <font-awesome-icon
              :icon="['fab', 'twitter']"
              size="lg"
            />
          </a>
        </li>
      </ul>
    </footer>
  </div>
</template>

<script>
import ContactForm from '../components/ContactForm';
import Vue from 'vue';
import Vodal from 'vodal'

Vue.component(Vodal.name, Vodal);

export default {
  name: 'Index',
  components: {
    ContactForm,
  },
  data() {
    return {
      firstJob: '01 Jan 2002',
      birth: '11 Dec 1986',
      modal: false,
    };
  },
  metaInfo() {
    return {
      title: this.$siteName,
    };
  },
}
</script>
